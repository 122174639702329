import * as React from "react";

function LinkedinIcon(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="Vector">
        <path
          d="M17.443 22H21.9981L22 13.9386C22 9.98116 21.1488 6.93914 16.532 6.93914C14.7671 6.87351 13.1092 7.78565 12.2175 9.31287H12.1563V7.30466H7.78346V21.9995H12.3385V14.73C12.3385 12.8131 12.7015 10.9565 15.0739 10.9565C17.4127 10.9565 17.443 13.1501 17.443 14.8541V22Z"
          fill="#0A66C2"
        />
        <path
          d="M0 2.64846C0.000262134 4.1109 1.18395 5.29623 2.64383 5.29597C3.3449 5.29585 4.01719 5.01674 4.51283 4.52006C5.00847 4.02337 5.28684 3.3498 5.28672 2.64751C5.28646 1.18507 4.10277 -0.00026244 2.64288 0C1.183 0.000262599 -0.000261963 1.18602 0 2.64846Z"
          fill="#0A66C2"
        />
        <path d="M0.361558 22H4.92136V7.30466H0.361558V22Z" fill="#0A66C2" />
      </g>
    </svg>
  );
}

export default LinkedinIcon;
