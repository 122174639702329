import * as React from "react";

function Logo(props) {
  return (
    <svg
      width="198"
      height="50"
      viewBox="0 0 198 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="Logos">
        <path
          id="Vector"
          d="M37.7664 24.2283L37.6981 24.1223C37.6671 24.0752 37.6489 24.0226 37.6434 23.9647C37.4977 22.3161 36.9851 20.7899 36.1055 19.3859C35.9015 19.0616 35.3961 18.4312 34.5894 17.4946"
          stroke="#808365"
          strokeWidth="2"
        />
        <path
          id="Vector_2"
          d="M16.2947 2.74458C26.0907 -1.16846 37.3045 1.91849 43.8279 9.96469C44.1666 10.3814 44.1229 10.4257 43.6967 10.0978C34.8841 3.30708 22.4739 5.72556 16.4531 14.9375C15.6664 16.144 15.2047 17.6006 14.2295 18.6522C12.6778 20.3179 10.1046 20.9565 8.00111 20.125C4.68205 18.8125 3.04301 14.8179 5.13279 11.7364C7.94466 7.5915 11.6653 4.59422 16.2947 2.74458Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_3"
          d="M84.1816 12.0379C85.5921 12.0207 86.722 10.8998 86.7052 9.53419C86.6885 8.16859 85.5314 7.07544 84.1209 7.09259C82.7104 7.10973 81.5805 8.23067 81.5973 9.59627C81.614 10.9619 82.7711 12.055 84.1816 12.0379Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_4"
          d="M113.477 12.0394C114.887 11.9929 115.993 10.8474 115.947 9.48097C115.902 8.11451 114.722 7.0445 113.312 7.09102C111.902 7.13755 110.796 8.28299 110.842 9.64944C110.887 11.0159 112.067 12.0859 113.477 12.0394Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_5"
          d="M37.766 24.2283L37.6978 24.1223C37.6668 24.0752 37.6486 24.0226 37.6431 23.9647C37.4974 22.3161 36.9848 20.7899 36.1052 19.3859C35.9012 19.0616 35.3958 18.4312 34.589 17.4946C33.4891 14.0562 31.7417 11.0154 29.3468 8.37228C29.3284 8.35163 29.3159 8.32645 29.3105 8.29936C29.3052 8.27227 29.3072 8.24425 29.3164 8.2182C29.3256 8.19215 29.3416 8.16902 29.3628 8.1512C29.384 8.13338 29.4096 8.12152 29.437 8.11685C31.7462 7.72736 34.0063 7.82428 36.2172 8.40761C41.29 9.74728 46.1525 11.9212 48.0702 16.8505C49.2994 20.0109 47.5894 23.5951 44.5025 24.9565C42.2643 25.9438 40.0188 25.7011 37.766 24.2283Z"
          fill="#F78803"
        />
        <path
          id="Vector_6"
          d="M123.297 14.5489C122.434 16.5462 124.319 17.3424 125.857 17.8098C129.837 19.019 134.836 20.5027 134.235 25.8777C133.833 29.4565 130.875 31.1929 127.438 31.5924C124.996 31.875 121.811 31.5951 119.631 30.1984C117.951 29.1223 117.874 26.8886 119.765 25.9375C119.804 25.918 119.848 25.91 119.892 25.9143C119.935 25.9186 119.976 25.9351 120.011 25.9619C122.059 27.5706 124.366 28.1902 126.93 27.8206C128.643 27.5734 129.831 26.3397 129.503 24.6168C129.288 23.5054 128.244 22.875 127.231 22.5435C125.939 22.1196 124.66 21.6947 123.393 21.269C119.819 20.0706 117.59 17.6984 118.675 13.6576C119.601 10.2092 123.581 9.24455 126.657 9.28532C128.553 9.31249 130.987 9.59238 132.369 10.7636C133.485 11.7129 133.566 12.836 132.612 14.1331C132.399 14.4248 132.135 14.4819 131.82 14.3043C130.002 13.2844 128.04 12.8696 125.933 13.0598C124.9 13.1522 123.734 13.5462 123.297 14.5489Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_7"
          d="M59.0221 15.5353C58.8727 20.5136 58.6624 25.6196 58.3911 30.8533C58.3879 30.9086 58.3675 30.9615 58.3326 31.0044C58.2978 31.0474 58.2504 31.0781 58.1971 31.0924C56.8831 31.4538 54.0558 31.7609 54.0257 29.7147C54.0093 28.5444 54.0403 27.3478 54.1186 26.125C54.41 21.596 54.7041 17.1078 55.001 12.6603C55.0392 12.0788 55.092 11.5027 55.1594 10.9321C55.1701 10.8512 55.2087 10.7763 55.2687 10.7201C56.5116 9.55435 58.5823 9.47011 60.0656 10.0462C61.2457 10.5027 61.7511 11.9484 62.1882 13.0679C63.3428 16.0118 64.4309 18.8206 65.4526 21.4946C65.6038 21.8895 65.7504 21.8877 65.8924 21.4891C67.1271 18.0272 68.5158 14.3668 70.0583 10.5081C70.0911 10.4266 70.1403 10.3525 70.2027 10.2904C70.2652 10.2283 70.3396 10.1794 70.4216 10.1467C71.5908 9.68116 72.8246 9.59058 74.1231 9.875C75.0792 10.0851 75.632 10.6821 75.7813 11.6658C75.8796 12.3143 75.9634 12.9665 76.0326 13.6223C76.5717 18.7563 76.9978 23.9076 77.3111 29.0761C77.342 29.5815 77.3621 30.0824 77.3712 30.5788C77.3748 30.7835 77.2801 30.9239 77.0871 31C76.2639 31.3206 75.3979 31.4194 74.4892 31.2962C73.0823 31.106 72.8857 30.2745 72.8092 29.0462C72.5141 24.3415 72.312 19.8831 72.2027 15.6712C72.179 14.7998 72.0215 14.7754 71.7301 15.5978C71.5608 16.0788 71.4433 16.5815 71.2575 17.0598C70.1776 19.8261 69.1295 22.5743 68.1133 25.3043C67.9439 25.7609 67.5888 26.0607 67.0479 26.2038C65.9853 26.4837 63.8682 26.606 63.3628 25.394C61.7912 21.6259 60.5009 18.3161 59.4919 15.4647C59.206 14.6567 59.0494 14.6803 59.0221 15.5353Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_8"
          d="M142.28 14.6359C142.278 14.6638 142.282 14.6917 142.292 14.7179C142.302 14.7441 142.317 14.768 142.336 14.7881C142.355 14.8082 142.378 14.8242 142.404 14.8349C142.429 14.8457 142.457 14.851 142.485 14.8505L147.235 14.837C147.338 14.8368 147.439 14.869 147.523 14.929C147.607 14.989 147.67 15.0737 147.702 15.1712C147.967 15.9565 148.15 17.3342 147.454 17.9456C147.028 18.3234 146.506 18.2989 145.935 18.3152C144.806 18.3478 143.666 18.3379 142.515 18.2853C142.49 18.2838 142.465 18.2874 142.441 18.2959C142.418 18.3044 142.396 18.3176 142.378 18.3346C142.36 18.3517 142.346 18.3723 142.336 18.3951C142.326 18.4179 142.321 18.4425 142.321 18.4674C142.315 21.0181 142.316 23.5534 142.323 26.0734C142.329 28.4565 145.356 28.2989 146.959 27.6549C147.037 27.6244 147.122 27.6179 147.204 27.6363C147.285 27.6548 147.359 27.6972 147.415 27.7581C147.658 28.019 147.802 28.3324 147.85 28.6984C148.096 30.5706 146.713 31.3587 145.107 31.5353C142.362 31.8424 139.032 31.25 138.19 28.3288C137.99 27.6386 137.891 26.856 137.893 25.981C137.907 20.2219 137.911 14.9927 137.904 10.2935C137.904 10.2435 137.921 10.1952 137.953 10.1566C137.985 10.118 138.029 10.0915 138.078 10.0815C139.037 9.8913 141.34 9.60326 141.933 10.5516C142.542 11.5217 142.334 13.5109 142.28 14.6359Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_9"
          d="M174.427 18.4674C174.425 21.1232 174.428 23.635 174.435 26.0027C174.441 28.4484 177.456 28.3451 179.049 27.6739C179.151 27.6306 179.265 27.6263 179.369 27.6619C179.474 27.6976 179.561 27.7706 179.614 27.8668C180.784 29.9918 179.161 31.3533 177.161 31.5462C173.43 31.9103 170.015 30.7609 170.015 26.3723C170.015 21.0462 170.016 15.6966 170.018 10.3234C170.018 10.2621 170.039 10.2028 170.078 10.1557C170.117 10.1086 170.171 10.0766 170.231 10.0652C170.896 9.9384 171.667 9.88586 172.545 9.9076C173.605 9.93478 174.329 10.462 174.386 11.5516C174.441 12.5824 174.452 13.6295 174.421 14.6929C174.421 14.7153 174.425 14.7375 174.433 14.7583C174.441 14.7791 174.453 14.798 174.469 14.8137C174.485 14.8295 174.504 14.8419 174.525 14.8501C174.546 14.8583 174.568 14.8622 174.591 14.8614C176.146 14.8306 177.737 14.8288 179.363 14.856C179.569 14.8596 179.707 14.9547 179.778 15.1413C180.226 16.3152 180.319 18.2255 178.579 18.3016C177.339 18.3578 176.023 18.3487 174.632 18.2745C174.495 18.2672 174.427 18.3315 174.427 18.4674Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_10"
          d="M95.668 18.2609C95.6117 18.2868 95.5642 18.3285 95.5308 18.3809C95.4974 18.4334 95.4796 18.4945 95.4795 18.5571L95.4767 30.9593C95.4767 31.0535 95.4303 31.1105 95.3374 31.1304C94.3176 31.346 93.346 31.3868 92.4227 31.2527C91.2343 31.0815 91.0049 30.0843 91.0103 29.0815C91.0231 25.5489 91.0286 21.8179 91.0267 17.8886C91.024 16.3913 92.5128 15.4837 93.7503 14.9837C96.0814 14.0435 98.4889 13.7663 100.973 14.1522C104.254 14.6603 106.497 16.6522 106.516 20.0109C106.539 23.8297 106.547 27.4583 106.538 30.8968C106.537 30.9538 106.517 31.009 106.481 31.053C106.445 31.0971 106.394 31.1273 106.338 31.1386C105.068 31.4049 102.18 31.8152 102.134 29.7147C102.076 26.9737 102.071 24.1268 102.12 21.1739C102.135 20.2953 102.037 19.5933 101.825 19.0679C100.935 16.8641 97.4354 17.4429 95.668 18.2609Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_11"
          d="M161.246 20.481C161.235 19.3098 161.061 18.3234 159.831 17.8207C157.69 16.9429 154.764 17.5679 152.715 18.4701C152.643 18.5019 152.563 18.5106 152.486 18.495C152.409 18.4794 152.338 18.4404 152.284 18.3832C151.617 17.6685 151.322 16.3777 152.032 15.5652C152.378 15.1685 152.803 14.8904 153.305 14.731C157.012 13.5435 163.391 13.3451 165.177 17.6522C165.452 18.317 165.583 19.3913 165.571 20.875C165.547 23.5707 165.541 26.0806 165.552 28.4049C165.56 29.769 163.519 30.7636 162.336 31.0897C158.337 32.1848 150.287 32.2092 150.546 25.9592C150.677 22.7582 153.234 21.4891 156.064 21.163C157.671 20.9801 159.339 20.8179 161.069 20.6766C161.189 20.6658 161.248 20.6005 161.246 20.481ZM158.05 24.0054C156.695 24.1196 154.849 24.4484 154.914 26.1821C155.018 28.9647 159.228 28.3777 160.968 27.8016C161.044 27.7765 161.111 27.728 161.158 27.6631C161.205 27.5982 161.23 27.5202 161.23 27.4402L161.233 23.8315C161.233 23.815 161.229 23.7986 161.223 23.7834C161.216 23.7683 161.206 23.7547 161.194 23.7437C161.181 23.7326 161.167 23.7243 161.151 23.7193C161.135 23.7143 161.118 23.7127 161.102 23.7147C160.105 23.8234 159.088 23.9203 158.05 24.0054Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_12"
          d="M185.157 30.9728C183.179 30.3315 182.114 28.4158 183.848 26.7119C183.936 26.6268 184.033 26.6132 184.141 26.6712C186.293 27.8234 188.053 28.4674 190.552 28.1033C191.874 27.9103 192.781 26.6386 191.866 25.4293C191.549 25.0109 190.924 24.7255 190.41 24.606C187.397 23.9103 183.269 23.3342 183.056 19.3533C182.903 16.5 185.031 14.6821 187.717 14.1875C189.733 13.8161 191.669 13.9085 193.527 14.4647C195.412 15.0299 196.723 16.3533 195.316 18.3152C195.147 18.5507 194.932 18.6033 194.672 18.4728C193.008 17.6522 189.957 16.8587 188.211 17.8641C186.853 18.644 187.32 20.1549 188.673 20.5054C191.475 21.2337 195.772 21.7636 196.499 25.2011C197.119 28.1467 195.625 30.4728 192.707 31.2337C190.22 31.884 187.703 31.7971 185.157 30.9728Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_13"
          d="M85.2437 14.5353C86.1151 14.75 86.3883 15.6467 86.3883 16.4375C86.3901 21.4828 86.3901 26.3044 86.3883 30.9022C86.3883 31.0054 86.3391 31.0707 86.2408 31.0978C85.2628 31.3669 82.7059 31.6957 82.1869 30.4701C82.0357 30.115 81.9601 29.75 81.9601 29.375C81.9601 24.3388 81.9565 19.4919 81.9492 14.8342C81.9492 14.7237 82.002 14.654 82.1077 14.625C83.2167 14.3225 84.2621 14.2926 85.2437 14.5353Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_14"
          d="M115.539 31.0951C114.121 31.4864 111.206 31.8261 111.195 29.481C111.177 24.5915 111.174 19.7047 111.187 14.8207C111.187 14.7228 111.234 14.6603 111.329 14.6332C112.804 14.1984 115.621 13.9294 115.623 16.2473C115.631 21.2799 115.632 26.1911 115.626 30.981C115.626 31.0069 115.617 31.032 115.602 31.0525C115.586 31.0731 115.564 31.0881 115.539 31.0951Z"
          fill="#F7AD0B"
        />
        <path
          id="Vector_15"
          d="M34.589 17.4946C35.3958 18.4312 35.9012 19.0616 36.1051 19.3859C36.9848 20.7899 37.4974 22.3161 37.6431 23.9647C37.6486 24.0227 37.6668 24.0752 37.6977 24.1223L37.766 24.2283C37.7096 24.2428 37.6832 24.3189 37.6868 24.4565C37.8944 32.6304 29.2048 37.6386 22.1405 33.3777C17.4692 30.5598 16.0105 24.0897 18.9471 19.5381C20.4368 17.2337 22.4528 15.75 24.9952 15.087C28.4062 14.1975 31.6041 15 34.589 17.4946Z"
          fill="#097EC6"
        />
        <path
          id="Vector_16"
          d="M3.14962 17.8451C4.85969 21.2446 8.06675 22.7717 11.8994 22.0244C12.0596 21.9918 12.1216 22.0552 12.0851 22.2147C11.6881 23.9121 11.6881 25.7219 12.0851 27.644C12.1125 27.7726 12.0605 27.8279 11.9294 27.8098C11.3394 27.7255 10.6974 27.5462 10.1156 27.5543C7.10882 27.5996 4.85605 28.9185 3.35724 31.5109C3.07587 31.9973 2.93655 32.5706 2.67703 33.0842C2.61329 33.2056 2.55957 33.202 2.51586 33.0734C0.681954 27.6965 0.682865 22.2618 2.51859 16.769C2.57323 16.606 2.63424 16.6032 2.70162 16.7609C2.8546 17.1196 2.97479 17.5 3.14962 17.8451Z"
          fill="#097EC6"
        />
        <path
          id="Vector_17"
          d="M21.6383 48.5652C15.1204 47.7283 9.17613 43.8994 5.40361 38.5652C4.15611 36.8025 3.90024 34.9293 4.63599 32.9456C5.28614 31.1984 7.02626 29.7527 8.90023 29.4239C10.9108 29.0706 12.6372 29.5933 14.0796 30.9918C15.0521 31.9348 15.5629 33.3804 16.295 34.5516C22.0098 43.6793 33.6224 46.1929 42.7082 40.3859C43.1617 40.0951 43.5605 39.7201 43.9894 39.3913C44.3718 39.1014 44.421 39.1485 44.1369 39.5326C43.4139 40.5109 42.6135 41.4022 41.7357 42.2065C36.0227 47.4312 29.3236 49.5507 21.6383 48.5652Z"
          fill="#691391"
        />
        <path
          id="Vector_18"
          d="M89.0058 42.8369C88.2972 42.8394 87.6165 42.5002 87.1133 41.894C86.6102 41.2877 86.3258 40.4642 86.3228 39.6044C86.3198 38.7446 86.5983 37.919 87.0972 37.3093C87.5961 36.6997 88.2745 36.3557 88.983 36.3533C89.6916 36.3508 90.3723 36.69 90.8755 37.2962C91.3786 37.9025 91.663 38.7261 91.666 39.5858C91.669 40.4456 91.3904 41.2712 90.8915 41.8809C90.3927 42.4906 89.7143 42.8345 89.0058 42.8369ZM88.9917 42.1739C89.4894 42.1739 89.9667 41.9022 90.3187 41.4186C90.6706 40.935 90.8684 40.279 90.8684 39.5951C90.8684 38.9112 90.6706 38.2552 90.3187 37.7716C89.9667 37.288 89.4894 37.0163 88.9917 37.0163C88.4939 37.0163 88.0166 37.288 87.6646 37.7716C87.3127 38.2552 87.115 38.9112 87.115 39.5951C87.115 40.279 87.3127 40.935 87.6646 41.4186C88.0166 41.9022 88.4939 42.1739 88.9917 42.1739Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_19"
          d="M96.627 41.4511C96.7362 41.5616 96.7845 41.539 96.7717 41.3832C96.6534 39.9774 96.6106 38.4574 96.6433 36.8234C96.647 36.6132 96.7544 36.5009 96.9657 36.4864C97.0039 36.4828 97.0431 36.4792 97.0832 36.4755C97.2871 36.4611 97.3891 36.5553 97.3891 36.7582L97.4 42.4919C97.4 42.5752 97.3609 42.6323 97.2826 42.663C96.9247 42.7962 96.6106 42.6766 96.4057 42.3451C95.5097 40.885 94.7047 39.4475 93.9908 38.0326C93.7541 37.5634 93.6485 37.5906 93.6739 38.1141C93.7377 39.4728 93.7495 40.9167 93.7095 42.4457C93.7079 42.5186 93.6798 42.5884 93.6305 42.642C93.5812 42.6955 93.5141 42.7291 93.4418 42.7364C93.4163 42.7382 93.3908 42.74 93.3653 42.7419C93.318 42.7441 93.2707 42.7367 93.2264 42.7202C93.1821 42.7037 93.1416 42.6784 93.1074 42.6459C93.0731 42.6134 93.0459 42.5743 93.0273 42.531C93.0087 42.4877 92.9992 42.4411 92.9992 42.394L92.9883 36.8207C92.9884 36.7631 93.002 36.7064 93.0281 36.6551C93.0541 36.6037 93.0918 36.5591 93.1383 36.5248C93.1847 36.4905 93.2385 36.4674 93.2955 36.4575C93.3524 36.4475 93.4109 36.4509 93.4663 36.4674C93.7122 36.5417 93.9052 36.6975 94.0455 36.9348C94.9233 38.4149 95.731 39.8505 96.4685 41.2419C96.5104 41.3198 96.5632 41.3895 96.627 41.4511Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_20"
          d="M108.368 37.1522C107.041 36.5761 105.88 38.2636 107.278 38.8342C107.774 39.0353 108.268 39.2591 108.762 39.5054C110.573 40.4076 110.002 42.5136 108.111 42.769C107.234 42.8868 106.453 42.6386 105.77 42.0245C105.639 41.9067 105.628 41.7781 105.738 41.6386L105.809 41.5435C105.912 41.4094 106.031 41.3967 106.164 41.5054C106.847 42.0652 108.292 42.5272 108.895 41.6332C109.393 40.8913 108.865 40.3832 108.169 40.0734C107.549 39.7989 106.877 39.5788 106.352 39.106C105.713 38.5353 105.762 37.4973 106.38 36.9538C107.185 36.2473 108.169 36.2174 109.333 36.8641C109.52 36.9674 109.589 37.1232 109.54 37.3315C109.535 37.3569 109.527 37.3813 109.516 37.4049C109.441 37.5716 109.322 37.6132 109.158 37.5299C108.894 37.3922 108.63 37.2663 108.368 37.1522Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_21"
          d="M100.006 39.1359L102.164 39.1087C102.197 39.1079 102.23 39.1199 102.254 39.1422C102.278 39.1645 102.293 39.1953 102.295 39.2283L102.314 39.587C102.315 39.604 102.313 39.6209 102.307 39.6369C102.301 39.6528 102.292 39.6674 102.28 39.6798C102.268 39.6922 102.254 39.7022 102.238 39.7091C102.223 39.716 102.206 39.7198 102.189 39.7201L100.006 39.7391C99.9726 39.7391 99.9403 39.7522 99.9163 39.7756C99.8923 39.7989 99.8785 39.8307 99.8778 39.8641L99.8559 41.9402C99.8559 41.9741 99.8695 42.0066 99.8935 42.0305C99.9176 42.0545 99.9503 42.0679 99.9843 42.0679L102.67 42.038C102.702 42.0379 102.733 42.0499 102.757 42.0715C102.781 42.0932 102.795 42.1229 102.798 42.1549L102.834 42.5245C102.835 42.5419 102.833 42.5594 102.827 42.576C102.822 42.5926 102.813 42.6079 102.801 42.6209C102.789 42.6339 102.775 42.6443 102.759 42.6516C102.743 42.6588 102.725 42.6627 102.708 42.663L99.2713 42.6848C99.2373 42.6848 99.2046 42.6713 99.1806 42.6474C99.1565 42.6234 99.1429 42.5909 99.1429 42.5571L99.1211 36.6331C99.1211 36.5993 99.1346 36.5668 99.1587 36.5428C99.1828 36.5189 99.2154 36.5054 99.2495 36.5054L102.544 36.5217C102.57 36.5218 102.596 36.53 102.617 36.545C102.639 36.5601 102.655 36.5814 102.664 36.606L102.803 37C102.81 37.0193 102.812 37.0399 102.809 37.06C102.806 37.0802 102.798 37.0993 102.786 37.1158C102.774 37.1323 102.758 37.1457 102.74 37.1548C102.721 37.164 102.701 37.1687 102.681 37.1685L100.001 37.1386C99.9836 37.1382 99.9666 37.1413 99.9507 37.1475C99.9348 37.1538 99.9204 37.1631 99.9082 37.175C99.8959 37.1869 99.8862 37.2011 99.8796 37.2168C99.873 37.2325 99.8696 37.2493 99.8696 37.2663L99.875 39.0082C99.875 39.0252 99.8785 39.042 99.8851 39.0577C99.8917 39.0733 99.9014 39.0875 99.9136 39.0994C99.9258 39.1113 99.9403 39.1207 99.9562 39.1269C99.9721 39.1332 99.9891 39.1362 100.006 39.1359Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_22"
          d="M112.051 37.1141L110.502 37.1821C110.466 37.184 110.431 37.1729 110.403 37.1509C110.374 37.1289 110.355 37.0975 110.349 37.0625L110.275 36.6848C110.271 36.6636 110.272 36.6418 110.277 36.6209C110.283 36.6 110.293 36.5806 110.306 36.564C110.32 36.5474 110.338 36.534 110.357 36.5249C110.377 36.5157 110.398 36.5109 110.42 36.5109L114.722 36.5163C114.76 36.5163 114.797 36.531 114.825 36.5573C114.852 36.5837 114.868 36.6196 114.87 36.6576L114.886 37.0272C114.887 37.0474 114.884 37.0675 114.876 37.0863C114.869 37.1051 114.857 37.1222 114.843 37.1365C114.829 37.1508 114.812 37.1619 114.793 37.1693C114.774 37.1767 114.754 37.1801 114.733 37.1793L113.116 37.1141C113.096 37.1134 113.076 37.1166 113.058 37.1237C113.039 37.1307 113.022 37.1414 113.008 37.1551C112.994 37.1688 112.983 37.1853 112.975 37.2034C112.967 37.2216 112.963 37.2411 112.963 37.2609L112.96 42.5734C112.96 42.6123 112.945 42.6496 112.917 42.6771C112.889 42.7046 112.852 42.7201 112.813 42.7201L112.351 42.7255C112.312 42.7255 112.274 42.7101 112.247 42.6826C112.219 42.655 112.204 42.6177 112.204 42.5788V37.2581C112.203 37.2386 112.199 37.2194 112.191 37.2015C112.183 37.1837 112.172 37.1676 112.158 37.1542C112.143 37.1408 112.126 37.1303 112.108 37.1234C112.09 37.1166 112.07 37.1134 112.051 37.1141Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_23"
          d="M116.074 40.9647L115.673 42.3288C115.609 42.5462 115.467 42.683 115.247 42.7391C115.132 42.7681 115.03 42.7382 114.941 42.6495C114.911 42.6192 114.89 42.5814 114.881 42.5402C114.871 42.499 114.873 42.456 114.886 42.4158C115.465 40.6096 116.086 38.7699 116.749 36.8967C116.88 36.5254 117.129 36.3859 117.495 36.4783C117.579 36.4992 117.656 36.5411 117.72 36.6C117.783 36.6589 117.831 36.7329 117.858 36.8152L119.751 42.4185C119.763 42.4526 119.767 42.489 119.762 42.5249C119.758 42.5607 119.745 42.5949 119.724 42.6248C119.704 42.6547 119.676 42.6793 119.645 42.6968C119.613 42.7143 119.577 42.7242 119.541 42.7255L119.445 42.7283C119.33 42.733 119.216 42.6992 119.122 42.6322C119.028 42.5653 118.959 42.469 118.926 42.3587L118.547 41.0842C118.524 41.0065 118.476 40.9381 118.411 40.8891C118.347 40.8402 118.268 40.8133 118.186 40.8125L116.301 40.7962C116.183 40.7962 116.107 40.8524 116.074 40.9647ZM116.405 40.0625C116.401 40.0768 116.4 40.0919 116.403 40.1066C116.405 40.1213 116.411 40.1351 116.42 40.147C116.429 40.1589 116.441 40.1685 116.455 40.1751C116.468 40.1817 116.483 40.185 116.498 40.1848L118.129 40.1685C118.143 40.1683 118.158 40.1646 118.171 40.1579C118.184 40.1511 118.196 40.1415 118.204 40.1296C118.213 40.1178 118.219 40.1041 118.221 40.0896C118.224 40.0751 118.223 40.0603 118.219 40.0462L117.38 37.4158C117.374 37.3962 117.362 37.3791 117.346 37.3669C117.329 37.3548 117.309 37.3482 117.289 37.3482C117.268 37.3482 117.248 37.3548 117.232 37.3669C117.215 37.3791 117.203 37.3962 117.197 37.4158L116.405 40.0625Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_24"
          d="M122.431 37.3505L122.423 42.4049C122.423 42.4473 122.414 42.4894 122.398 42.5285C122.381 42.5676 122.357 42.603 122.326 42.6326C122.296 42.6623 122.26 42.6855 122.22 42.701C122.18 42.7165 122.138 42.7239 122.095 42.7228L121.95 42.7201C121.876 42.718 121.806 42.6873 121.755 42.6345C121.703 42.5817 121.674 42.5111 121.674 42.4375L121.666 37.413C121.666 37.1956 121.557 37.0933 121.338 37.106L120.161 37.1739C119.935 37.1866 119.791 37.0842 119.729 36.8668C119.706 36.7799 119.73 36.6902 119.803 36.5978C119.825 36.5699 119.854 36.5474 119.886 36.5319C119.918 36.5163 119.953 36.5082 119.989 36.5081L124.037 36.5163C124.234 36.5163 124.339 36.6141 124.352 36.8098C124.355 36.8714 124.347 36.9321 124.327 36.9918C124.31 37.0441 124.276 37.0893 124.231 37.1207C124.185 37.1521 124.131 37.1679 124.076 37.1658L122.669 37.1223C122.51 37.1168 122.431 37.1929 122.431 37.3505Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_25"
          d="M127.862 40.9864L127.386 42.413C127.315 42.6286 127.165 42.7337 126.935 42.7283H126.84C126.804 42.7272 126.768 42.7177 126.736 42.7005C126.704 42.6833 126.677 42.659 126.656 42.6294C126.636 42.5998 126.622 42.5658 126.617 42.5301C126.612 42.4945 126.615 42.4581 126.627 42.4239C127.253 40.5543 127.883 38.6884 128.517 36.8261C128.588 36.6178 128.779 36.49 129.091 36.4429C129.202 36.427 129.315 36.4504 129.41 36.5091C129.506 36.5677 129.577 36.6578 129.613 36.7636L131.492 42.3913C131.565 42.6069 131.487 42.7147 131.257 42.7147H131.172C130.89 42.7129 130.712 42.5761 130.637 42.3043L130.306 41.0761C130.285 40.998 130.239 40.9289 130.175 40.8798C130.111 40.8307 130.032 40.8041 129.951 40.8043L128.102 40.8125C127.98 40.8125 127.9 40.8705 127.862 40.9864ZM128.121 40.0679C128.117 40.0809 128.116 40.0947 128.118 40.1082C128.12 40.1217 128.125 40.1345 128.133 40.1457C128.141 40.1568 128.151 40.1659 128.164 40.1722C128.176 40.1785 128.189 40.1819 128.203 40.1821L129.889 40.1929C129.902 40.1926 129.915 40.1893 129.927 40.1831C129.939 40.1769 129.949 40.1681 129.957 40.1573C129.965 40.1466 129.97 40.1341 129.973 40.121C129.975 40.1078 129.974 40.0943 129.97 40.0815L129.148 37.4402C129.143 37.4226 129.131 37.4073 129.117 37.3964C129.102 37.3854 129.083 37.3796 129.065 37.3796C129.046 37.3796 129.028 37.3854 129.013 37.3964C128.998 37.4073 128.987 37.4226 128.982 37.4402L128.121 40.0679Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_26"
          d="M133.123 37.1114L131.82 37.1766C131.784 37.1784 131.75 37.1709 131.722 37.1549C131.496 37.0245 131.428 36.8614 131.517 36.6658C131.539 36.618 131.574 36.5776 131.618 36.5491C131.662 36.5207 131.713 36.5056 131.766 36.5054L135.836 36.5163C135.996 36.5163 136.081 36.5951 136.09 36.7527L136.098 36.894C136.111 37.0861 136.021 37.1775 135.828 37.1685L134.399 37.1114C134.374 37.1103 134.349 37.1143 134.325 37.1233C134.302 37.1322 134.28 37.1458 134.262 37.1634C134.244 37.1809 134.23 37.2019 134.22 37.2252C134.21 37.2485 134.205 37.2736 134.205 37.2989L134.199 42.3451C134.199 42.5571 134.097 42.6884 133.891 42.7391C133.807 42.7609 133.719 42.7545 133.626 42.7201C133.571 42.701 133.523 42.6655 133.489 42.6188C133.455 42.572 133.437 42.5163 133.437 42.4592L133.413 37.3832C133.413 37.3461 133.405 37.3093 133.39 37.2753C133.376 37.2412 133.354 37.2105 133.327 37.185C133.3 37.1595 133.268 37.1399 133.233 37.1272C133.198 37.1146 133.16 37.1092 133.123 37.1114Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_27"
          d="M139.668 40.8995L139.136 42.4538C139.108 42.5353 139.055 42.6062 138.985 42.6564C138.915 42.7065 138.831 42.7336 138.745 42.7337L138.636 42.731C138.405 42.731 138.326 42.6223 138.401 42.4049C139.018 40.586 139.625 38.7708 140.22 36.9592C140.317 36.6658 140.505 36.5018 140.786 36.4674C140.919 36.4511 141.054 36.4812 141.167 36.5525C141.281 36.6238 141.366 36.732 141.409 36.8587L143.272 42.4049C143.339 42.6078 143.266 42.7147 143.053 42.7255L142.941 42.731C142.693 42.7437 142.532 42.6322 142.457 42.3967L142.009 40.9891C141.989 40.9235 141.946 40.8669 141.888 40.8293C141.831 40.7916 141.761 40.7751 141.693 40.7826C141.106 40.8496 140.51 40.8406 139.903 40.7554C139.785 40.7391 139.707 40.7871 139.668 40.8995ZM139.914 40.0054C139.908 40.0258 139.906 40.0475 139.91 40.0686C139.913 40.0897 139.922 40.1097 139.935 40.1269C139.947 40.1441 139.964 40.1581 139.983 40.1677C140.003 40.1773 140.024 40.1822 140.045 40.1821H141.583C141.604 40.1816 141.625 40.1764 141.644 40.1668C141.662 40.1572 141.678 40.1435 141.691 40.1266C141.703 40.1098 141.711 40.0904 141.715 40.0698C141.719 40.0493 141.718 40.0282 141.712 40.0081L140.95 37.4755C140.941 37.4473 140.924 37.4225 140.9 37.4049C140.877 37.3872 140.848 37.3777 140.818 37.3777C140.789 37.3777 140.76 37.3872 140.737 37.4049C140.713 37.4225 140.696 37.4473 140.687 37.4755L139.914 40.0054Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_28"
          d="M147.303 37.1141L145.861 37.1739C145.796 37.1762 145.733 37.1531 145.686 37.1099C145.638 37.0666 145.61 37.0066 145.607 36.9429L145.599 36.8071C145.59 36.6078 145.686 36.5081 145.888 36.5081L149.953 36.5136C150.119 36.5136 150.214 36.5951 150.237 36.7581C150.243 36.7926 150.24 36.8261 150.229 36.8587C150.156 37.0688 150.009 37.1703 149.786 37.163L148.426 37.1168C148.401 37.1161 148.375 37.1205 148.351 37.1297C148.327 37.139 148.306 37.1529 148.287 37.1706C148.269 37.1883 148.254 37.2096 148.244 37.233C148.235 37.2564 148.229 37.2816 148.229 37.3071L148.21 42.4592C148.208 42.6277 148.124 42.7074 147.956 42.6984L147.773 42.6902C147.571 42.6812 147.471 42.577 147.473 42.3777L147.489 37.2935C147.489 37.2693 147.485 37.2454 147.476 37.2231C147.466 37.2008 147.453 37.1806 147.435 37.1638C147.418 37.147 147.397 37.1339 147.374 37.1254C147.352 37.1168 147.328 37.113 147.303 37.1141Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_29"
          d="M151.792 36.4864H151.497C151.369 36.4864 151.265 36.5898 151.265 36.7174V42.4728C151.265 42.6004 151.369 42.7038 151.497 42.7038H151.792C151.92 42.7038 152.024 42.6004 152.024 42.4728V36.7174C152.024 36.5898 151.92 36.4864 151.792 36.4864Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_30"
          d="M157.96 38.2391L156.758 41.5163C156.476 42.2826 156.186 42.2862 155.889 41.5272C155.487 40.5 155.053 39.2699 154.586 37.837C154.57 37.7844 154.536 37.7473 154.485 37.7255C154.47 37.7194 154.453 37.7172 154.437 37.7193C154.421 37.7214 154.405 37.7277 154.392 37.7376C154.379 37.7474 154.368 37.7605 154.362 37.7755C154.355 37.7906 154.353 37.807 154.354 37.8234C154.48 39.2962 154.507 40.8415 154.436 42.4592C154.435 42.4973 154.425 42.5346 154.408 42.5688C154.391 42.603 154.367 42.6334 154.338 42.6579C154.309 42.6824 154.275 42.7006 154.238 42.7114C154.201 42.7221 154.163 42.725 154.125 42.7201L153.988 42.7038C153.926 42.6965 153.868 42.6662 153.826 42.6186C153.785 42.571 153.761 42.5095 153.761 42.4456L153.75 36.7174C153.75 36.6743 153.764 36.6324 153.79 36.5976C153.815 36.5629 153.851 36.5373 153.893 36.5245C154.355 36.3868 154.658 36.5081 154.802 36.8886C155.265 38.1223 155.702 39.3442 156.113 40.5543C156.285 41.0525 156.443 41.0489 156.589 40.5435C156.955 39.2862 157.402 38.029 157.93 36.7717C157.97 36.6757 158.038 36.6051 158.135 36.5598C158.332 36.4674 158.531 36.4493 158.733 36.5054C158.857 36.5417 158.919 36.6232 158.919 36.75L158.924 42.4701C158.925 42.5024 158.918 42.5343 158.905 42.5638C158.892 42.5934 158.872 42.6199 158.848 42.6416C158.824 42.6634 158.795 42.6799 158.764 42.6902C158.733 42.7004 158.7 42.7041 158.668 42.7011L158.43 42.6793C158.257 42.663 158.17 42.5679 158.17 42.394C158.163 41.0408 158.197 39.6766 158.272 38.3016C158.31 37.5879 158.206 37.567 157.96 38.2391Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_31"
          d="M161.383 37.2364L161.391 39.0625C161.391 39.0865 161.4 39.1096 161.417 39.1269C161.434 39.1441 161.457 39.1542 161.481 39.1549L163.798 39.2391C163.81 39.2394 163.823 39.2424 163.835 39.2479C163.847 39.2533 163.857 39.2612 163.866 39.2709C163.874 39.2806 163.88 39.292 163.884 39.3043C163.888 39.3166 163.889 39.3296 163.888 39.3424L163.852 39.6223C163.85 39.6439 163.839 39.664 163.823 39.6789C163.807 39.6939 163.787 39.7027 163.765 39.7038L161.476 39.8043C161.452 39.8057 161.43 39.8156 161.414 39.8323C161.398 39.8489 161.389 39.8709 161.388 39.894L161.374 41.9565C161.374 41.981 161.384 42.0045 161.402 42.0218C161.419 42.0392 161.443 42.0489 161.467 42.0489L164.142 42.0571C164.163 42.0573 164.184 42.0651 164.201 42.079C164.217 42.0929 164.228 42.112 164.232 42.1331L164.292 42.4375C164.294 42.4507 164.294 42.4642 164.29 42.4772C164.287 42.4902 164.281 42.5023 164.272 42.5126C164.264 42.5229 164.253 42.5313 164.241 42.5371C164.229 42.5429 164.215 42.546 164.202 42.5462L160.722 42.6603C160.709 42.6607 160.697 42.6586 160.685 42.6541C160.674 42.6496 160.663 42.6429 160.654 42.6342C160.645 42.6256 160.638 42.6153 160.633 42.6039C160.628 42.5925 160.626 42.5803 160.626 42.5679L160.629 36.5706C160.629 36.5583 160.631 36.546 160.636 36.5346C160.641 36.5233 160.648 36.5129 160.657 36.5043C160.666 36.4957 160.676 36.489 160.688 36.4845C160.7 36.48 160.712 36.4779 160.724 36.4783L164.054 36.5435C164.077 36.544 164.099 36.553 164.116 36.5685C164.132 36.584 164.142 36.6051 164.144 36.6277L164.199 37.1005C164.201 37.1138 164.199 37.1272 164.195 37.1399C164.191 37.1526 164.184 37.1642 164.175 37.1741C164.166 37.1839 164.155 37.1916 164.143 37.1967C164.13 37.2019 164.117 37.2043 164.103 37.2038L161.478 37.144C161.466 37.1436 161.453 37.1458 161.442 37.1502C161.43 37.1547 161.42 37.1615 161.411 37.1701C161.402 37.1787 161.395 37.189 161.39 37.2004C161.385 37.2118 161.383 37.224 161.383 37.2364Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_32"
          d="M83.7496 39.269H58.1915C58.0542 39.269 57.9429 39.3797 57.9429 39.5163V39.6848C57.9429 39.8213 58.0542 39.9321 58.1915 39.9321H83.7496C83.8869 39.9321 83.9982 39.8213 83.9982 39.6848V39.5163C83.9982 39.3797 83.8869 39.269 83.7496 39.269Z"
          fill="#FEBD02"
        />
        <path
          id="Vector_33"
          d="M192.386 39.2939L166.861 39.2496C166.679 39.2493 166.532 39.395 166.532 39.5751L166.532 39.5805C166.532 39.7606 166.678 39.9069 166.859 39.9072L192.385 39.9515C192.566 39.9518 192.713 39.8061 192.713 39.626L192.713 39.6206C192.713 39.4405 192.567 39.2942 192.386 39.2939Z"
          fill="#FEBD02"
        />
      </g>
    </svg>
  );
}

export default Logo;
