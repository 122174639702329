import * as React from "react";

function Quote(props) {
  return (
    <svg
      width="34"
      height="25"
      viewBox="0 0 34 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        id="&#226;&#128;&#156;"
        d="M8.26818 25C6.72273 25 5.30606 24.5846 4.01818 23.7539C2.78182 22.9232 1.80303 21.7809 1.08182 20.3271C0.360606 18.8733 0 17.1859 0 15.2648C0 13.6033 0.309091 11.9938 0.927272 10.4361C1.54545 8.82658 2.42121 7.34683 3.55455 5.99689C4.73939 4.59502 6.10455 3.37487 7.65 2.33645C9.19546 1.24611 10.8697 0.46729 12.6727 0L16.2273 4.36137C14.0636 5.29595 12.3121 6.46417 10.9727 7.86604C9.68485 9.21599 9.04091 10.3583 9.04091 11.2928C9.04091 11.864 9.22121 12.4351 9.58182 13.0062C9.99394 13.5254 10.5864 14.0187 11.3591 14.486C12.5439 15.3686 13.3682 16.2253 13.8318 17.0561C14.2955 17.8868 14.5273 18.7954 14.5273 19.7819C14.5273 21.3915 13.9348 22.6635 12.75 23.5981C11.6167 24.5327 10.1227 25 8.26818 25ZM26.0409 25C24.4955 25 23.0788 24.5846 21.7909 23.7539C20.5545 22.9232 19.5758 21.7809 18.8545 20.3271C18.1333 18.8733 17.7727 17.1859 17.7727 15.2648C17.7727 13.6033 18.0818 11.9938 18.7 10.4361C19.3182 8.82658 20.1939 7.34683 21.3273 5.99689C22.5121 4.59502 23.8773 3.37487 25.4227 2.33645C26.9682 1.24611 28.6424 0.46729 30.4455 0L34 4.36137C31.8364 5.29595 30.0848 6.46417 28.7455 7.86604C27.4576 9.21599 26.8136 10.3583 26.8136 11.2928C26.8136 11.864 26.9939 12.4351 27.3545 13.0062C27.7667 13.5254 28.3591 14.0187 29.1318 14.486C30.3167 15.3686 31.1409 16.2253 31.6045 17.0561C32.0682 17.8868 32.3 18.7954 32.3 19.7819C32.3 21.3915 31.7076 22.6635 30.5227 23.5981C29.3894 24.5327 27.8955 25 26.0409 25Z"
        fill="white"
      />
    </svg>
  );
}

export default Quote;
