import * as React from "react";

function LogoSm(props) {
  return (
    <svg
      width="158"
      height="40"
      viewBox="0 0 158 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g clipPath="url(#clip0_919_23972)">
        <path
          d="M30.1709 19.3826L30.1163 19.2978C30.0916 19.2602 30.077 19.2181 30.0726 19.1718C29.9563 17.8529 29.5467 16.6319 28.844 15.5087C28.6811 15.2493 28.2773 14.7449 27.6328 13.9957"
          stroke="#808365"
          strokeWidth="2"
        />
        <path
          d="M13.0171 2.19566C20.8429 -0.934777 29.8013 1.53479 35.0127 7.97174C35.2833 8.30508 35.2484 8.34058 34.9079 8.07827C27.8678 2.64566 17.9535 4.58044 13.1436 11.95C12.5151 12.9152 12.1463 14.0804 11.3672 14.9217C10.1277 16.2544 8.07193 16.7652 6.39155 16.1C3.74003 15.05 2.43063 11.8544 4.10011 9.38913C6.34644 6.07319 9.31877 3.67537 13.0171 2.19566Z"
          fill="#F7AD0B"
        />
        <path
          d="M67.251 9.63028C68.3778 9.61656 69.2804 8.71981 69.267 7.62733C69.2536 6.53485 68.3293 5.66034 67.2024 5.67405C66.0756 5.68777 65.173 6.58451 65.1864 7.677C65.1998 8.76948 66.1241 9.64399 67.251 9.63028Z"
          fill="#F7AD0B"
        />
        <path
          d="M90.6536 9.63154C91.7799 9.59432 92.6634 8.67796 92.627 7.5848C92.5906 6.49163 91.6481 5.63562 90.5218 5.67284C89.3955 5.71006 88.5119 6.62642 88.5483 7.71958C88.5847 8.81274 89.5273 9.66875 90.6536 9.63154Z"
          fill="#F7AD0B"
        />
        <path
          d="M30.1703 19.3826L30.1158 19.2978C30.091 19.2601 30.0765 19.2181 30.0721 19.1717C29.9557 17.8529 29.5462 16.6319 28.8435 15.5087C28.6805 15.2493 28.2768 14.7449 27.6323 13.9956C26.7535 11.2449 25.3576 8.81231 23.4444 6.69781C23.4297 6.68129 23.4197 6.66115 23.4154 6.63948C23.4111 6.6178 23.4128 6.59539 23.4201 6.57455C23.4275 6.55371 23.4403 6.5352 23.4572 6.52095C23.4741 6.50669 23.4946 6.4972 23.5164 6.49347C25.3612 6.18187 27.1667 6.25941 28.933 6.72607C32.9855 7.79781 36.87 9.53694 38.402 13.4804C39.3841 16.0087 38.018 18.8761 35.5519 19.9652C33.7639 20.7551 31.97 20.5609 30.1703 19.3826Z"
          fill="#F78803"
        />
        <path
          d="M98.4986 11.6391C97.809 13.237 99.3148 13.8739 100.543 14.2478C103.723 15.2152 107.717 16.4022 107.237 20.7022C106.916 23.5652 104.552 24.9543 101.807 25.2739C99.856 25.5 97.3114 25.2761 95.5699 24.1587C94.2278 23.2978 94.1667 21.5109 95.6769 20.75C95.7083 20.7344 95.7435 20.728 95.7782 20.7314C95.813 20.7349 95.846 20.7481 95.8733 20.7696C97.51 22.0565 99.3526 22.5522 101.401 22.2565C102.769 22.0587 103.719 21.0717 103.457 19.6935C103.284 18.8043 102.451 18.3 101.641 18.0348C100.61 17.6957 99.5876 17.3558 98.575 17.0152C95.7205 16.0565 93.9397 14.1587 94.8061 10.9261C95.5459 8.16739 98.7256 7.39565 101.183 7.42826C102.697 7.45 104.642 7.67391 105.746 8.61087C106.638 9.37029 106.703 10.2688 105.94 11.3065C105.77 11.5399 105.559 11.5855 105.307 11.4435C103.855 10.6275 102.288 10.2957 100.605 10.4478C99.7796 10.5217 98.8478 10.837 98.4986 11.6391Z"
          fill="#F7AD0B"
        />
        <path
          d="M47.1511 12.4283C47.0318 16.4109 46.8638 20.4956 46.647 24.6826C46.6445 24.7269 46.6282 24.7692 46.6003 24.8035C46.5725 24.8379 46.5346 24.8625 46.492 24.8739C45.4424 25.163 43.1837 25.4087 43.1596 23.7717C43.1466 22.8355 43.1713 21.8783 43.2338 20.9C43.4666 17.2768 43.7016 13.6862 43.9387 10.1283C43.9693 9.66304 44.0115 9.20217 44.0653 8.74564C44.0738 8.68094 44.1047 8.62103 44.1526 8.57608C45.1456 7.64347 46.7998 7.57608 47.9848 8.03695C48.9275 8.40217 49.3312 9.55869 49.6804 10.4543C50.6028 12.8094 51.4721 15.0565 52.2883 17.1956C52.409 17.5116 52.5262 17.5101 52.6396 17.1913C53.6261 14.4217 54.7354 11.4935 55.9677 8.40651C55.9939 8.34127 56.0332 8.28201 56.0831 8.23231C56.1329 8.18261 56.1924 8.14352 56.2579 8.11738C57.192 7.74492 58.1776 7.67246 59.215 7.89999C59.9788 8.06811 60.4203 8.54564 60.5396 9.3326C60.6182 9.85144 60.6851 10.3732 60.7404 10.8978C61.1711 15.0051 61.5115 19.1261 61.7617 23.2609C61.7865 23.6652 61.8025 24.0659 61.8098 24.463C61.8127 24.6268 61.737 24.7391 61.5828 24.8C60.9252 25.0565 60.2334 25.1355 59.5074 25.0369C58.3835 24.8848 58.2264 24.2196 58.1653 23.2369C57.9296 19.4732 57.7681 15.9065 57.6808 12.5369C57.6619 11.8398 57.536 11.8203 57.3033 12.4783C57.168 12.863 57.0741 13.2652 56.9257 13.6478C56.063 15.8609 55.2257 18.0594 54.4139 20.2435C54.2786 20.6087 53.9949 20.8485 53.5628 20.963C52.7138 21.1869 51.0225 21.2848 50.6188 20.3152C49.3633 17.3007 48.3325 14.6529 47.5265 12.3717C47.2981 11.7254 47.1729 11.7442 47.1511 12.4283Z"
          fill="#F7AD0B"
        />
        <path
          d="M113.664 11.7087C113.663 11.731 113.666 11.7533 113.674 11.7743C113.682 11.7953 113.694 11.8144 113.709 11.8305C113.724 11.8466 113.743 11.8593 113.763 11.8679C113.784 11.8765 113.806 11.8808 113.828 11.8804L117.623 11.8696C117.705 11.8694 117.786 11.8952 117.853 11.9432C117.92 11.9912 117.97 12.059 117.996 12.137C118.208 12.7652 118.354 13.8674 117.797 14.3565C117.457 14.6587 117.04 14.6391 116.584 14.6522C115.682 14.6783 114.771 14.6703 113.852 14.6283C113.832 14.6271 113.812 14.63 113.793 14.6367C113.774 14.6435 113.757 14.6541 113.743 14.6677C113.728 14.6814 113.717 14.6978 113.709 14.7161C113.701 14.7343 113.697 14.754 113.697 14.7739C113.692 16.8145 113.693 18.8427 113.699 20.8587C113.703 22.7652 116.121 22.6391 117.402 22.1239C117.465 22.0995 117.533 22.0943 117.598 22.1091C117.663 22.1238 117.722 22.1578 117.767 22.2065C117.96 22.4152 118.076 22.6659 118.114 22.9587C118.31 24.4565 117.206 25.087 115.923 25.2283C113.729 25.4739 111.069 25 110.397 22.663C110.237 22.1109 110.158 21.4848 110.159 20.7848C110.171 16.1775 110.174 11.9942 110.168 8.23478C110.168 8.19484 110.182 8.15618 110.208 8.1253C110.233 8.09442 110.268 8.0732 110.308 8.06521C111.074 7.91304 112.913 7.68261 113.387 8.4413C113.874 9.21739 113.708 10.8087 113.664 11.7087Z"
          fill="#F7AD0B"
        />
        <path
          d="M139.346 14.7739C139.344 16.8986 139.346 18.908 139.352 20.8022C139.357 22.7587 141.766 22.6761 143.038 22.1391C143.119 22.1044 143.21 22.101 143.294 22.1296C143.377 22.1581 143.447 22.2165 143.49 22.2935C144.424 23.9935 143.128 25.0826 141.53 25.237C138.549 25.5283 135.821 24.6087 135.821 21.0978C135.821 16.837 135.822 12.5572 135.823 8.2587C135.824 8.20968 135.841 8.16225 135.872 8.12456C135.903 8.08687 135.946 8.06128 135.994 8.05218C136.525 7.95073 137.141 7.9087 137.842 7.92609C138.689 7.94783 139.267 8.36957 139.313 9.24131C139.357 10.0659 139.366 10.9036 139.341 11.7544C139.341 11.7722 139.344 11.79 139.35 11.8066C139.357 11.8233 139.367 11.8384 139.38 11.851C139.392 11.8636 139.407 11.8735 139.424 11.8801C139.441 11.8867 139.459 11.8897 139.477 11.8891C140.719 11.8645 141.99 11.863 143.289 11.8848C143.454 11.8877 143.564 11.9638 143.621 12.113C143.979 13.0522 144.053 14.5804 142.663 14.6413C141.672 14.6862 140.621 14.679 139.509 14.6196C139.4 14.6138 139.346 14.6652 139.346 14.7739Z"
          fill="#F7AD0B"
        />
        <path
          d="M76.427 14.6087C76.3821 14.6294 76.3441 14.6628 76.3174 14.7047C76.2907 14.7467 76.2765 14.7956 76.2764 14.8457L76.2742 24.7674C76.2742 24.8428 76.2371 24.8884 76.1629 24.9044C75.3482 25.0768 74.572 25.1094 73.8344 25.0022C72.8851 24.8652 72.7018 24.0674 72.7061 23.2652C72.7163 20.4391 72.7207 17.4544 72.7192 14.3109C72.717 13.1131 73.9064 12.387 74.895 11.987C76.7572 11.2348 78.6806 11.0131 80.6651 11.3217C83.286 11.7283 85.0777 13.3217 85.093 16.0087C85.1119 19.0638 85.1177 21.9667 85.1104 24.7174C85.1103 24.763 85.0944 24.8072 85.0654 24.8424C85.0364 24.8777 84.996 24.9019 84.9511 24.9109C83.9363 25.1239 81.6296 25.4522 81.5925 23.7717C81.546 21.579 81.5423 19.3015 81.5816 16.9391C81.5933 16.2362 81.5147 15.6746 81.3459 15.2544C80.6345 13.4913 77.8389 13.9544 76.427 14.6087Z"
          fill="#F7AD0B"
        />
        <path
          d="M128.816 16.3848C128.807 15.4478 128.667 14.6587 127.685 14.2565C125.974 13.5543 123.637 14.0543 122 14.7761C121.943 14.8015 121.879 14.8085 121.817 14.796C121.755 14.7835 121.699 14.7523 121.656 14.7065C121.123 14.1348 120.887 13.1022 121.455 12.4522C121.731 12.1348 122.07 11.9123 122.472 11.7848C125.433 10.8348 130.529 10.6761 131.956 14.1217C132.176 14.6536 132.281 15.513 132.27 16.7C132.251 18.8565 132.246 20.8645 132.255 22.7239C132.262 23.8152 130.631 24.6109 129.686 24.8717C126.492 25.7478 120.06 25.7674 120.268 20.7674C120.372 18.2065 122.415 17.1913 124.676 16.9304C125.959 16.784 127.292 16.6543 128.674 16.5413C128.77 16.5326 128.817 16.4804 128.816 16.3848ZM126.262 19.2043C125.18 19.2956 123.705 19.5587 123.757 20.9456C123.84 23.1717 127.203 22.7022 128.593 22.2413C128.654 22.2212 128.707 22.1824 128.745 22.1305C128.782 22.0786 128.803 22.0162 128.803 21.9522L128.805 19.0652C128.805 19.052 128.802 19.0388 128.797 19.0267C128.791 19.0146 128.784 19.0038 128.774 18.9949C128.764 18.9861 128.752 18.9795 128.739 18.9755C128.727 18.9715 128.713 18.9702 128.7 18.9717C127.904 19.0587 127.092 19.1362 126.262 19.2043Z"
          fill="#F7AD0B"
        />
        <path
          d="M147.918 24.7783C146.338 24.2652 145.487 22.7326 146.872 21.3696C146.942 21.3015 147.02 21.2906 147.106 21.337C148.826 22.2587 150.231 22.7739 152.228 22.4826C153.284 22.3283 154.009 21.3109 153.278 20.3435C153.024 20.0087 152.525 19.7804 152.114 19.6848C149.707 19.1283 146.41 18.6674 146.24 15.4826C146.117 13.2 147.817 11.7457 149.963 11.35C151.573 11.0529 153.12 11.1268 154.604 11.5717C156.11 12.0239 157.158 13.0826 156.034 14.6522C155.899 14.8406 155.727 14.8826 155.519 14.7783C154.19 14.1217 151.752 13.487 150.358 14.2913C149.273 14.9152 149.646 16.1239 150.726 16.4044C152.966 16.987 156.398 17.4109 156.979 20.1609C157.474 22.5174 156.28 24.3783 153.95 24.987C151.962 25.5073 149.952 25.4377 147.918 24.7783Z"
          fill="#F7AD0B"
        />
        <path
          d="M68.0996 11.6282C68.7958 11.8 69.014 12.5174 69.014 13.15C69.0155 17.1862 69.0155 21.0435 69.014 24.7217C69.014 24.8043 68.9748 24.8565 68.8962 24.8782C68.1149 25.0935 66.0723 25.3565 65.6576 24.3761C65.5369 24.092 65.4765 23.8 65.4765 23.5C65.4765 19.471 65.4736 15.5935 65.4678 11.8674C65.4678 11.779 65.51 11.7232 65.5944 11.7C66.4804 11.458 67.3155 11.434 68.0996 11.6282Z"
          fill="#F7AD0B"
        />
        <path
          d="M92.3011 24.8761C91.1685 25.1891 88.8399 25.4609 88.8312 23.5848C88.8166 19.6732 88.8145 15.7638 88.8246 11.8565C88.8246 11.7783 88.8625 11.7283 88.9381 11.7065C90.1166 11.3587 92.3666 11.1435 92.3687 12.9978C92.3746 17.0239 92.3753 20.9529 92.3709 24.7848C92.3708 24.8055 92.3639 24.8256 92.3513 24.842C92.3387 24.8585 92.3211 24.8705 92.3011 24.8761Z"
          fill="#F7AD0B"
        />
        <path
          d="M27.6322 13.9956C28.2767 14.7449 28.6804 15.2493 28.8434 15.5087C29.5461 16.6319 29.9557 17.8529 30.072 19.1717C30.0764 19.2181 30.091 19.2601 30.1157 19.2978L30.1702 19.3826C30.1251 19.3942 30.104 19.4551 30.107 19.5652C30.2728 26.1043 23.3309 30.1109 17.6874 26.7022C13.9556 24.4478 12.7902 19.2717 15.1362 15.6304C16.3263 13.787 17.9369 12.6 19.9679 12.0696C22.6929 11.358 25.2477 12 27.6322 13.9956Z"
          fill="#097EC6"
        />
        <path
          d="M2.51666 14.2761C3.88279 16.9957 6.44484 18.2174 9.50663 17.6196C9.63466 17.5935 9.68413 17.6442 9.65503 17.7717C9.33787 19.1297 9.33787 20.5775 9.65503 22.1152C9.67686 22.2181 9.63539 22.2623 9.53064 22.2478C9.05926 22.1804 8.54641 22.037 8.08158 22.0435C5.67957 22.0797 3.87988 23.1348 2.68252 25.2087C2.45774 25.5978 2.34644 26.0565 2.13912 26.4674C2.0882 26.5645 2.04528 26.5616 2.01036 26.4587C0.545298 22.1572 0.546025 17.8094 2.01254 13.4152C2.05619 13.2848 2.10493 13.2826 2.15876 13.4087C2.28097 13.6957 2.37699 14 2.51666 14.2761Z"
          fill="#097EC6"
        />
        <path
          d="M17.2858 38.8522C12.0788 38.1826 7.33007 35.1196 4.31629 30.8522C3.3197 29.442 3.11528 27.9435 3.70306 26.3565C4.22245 24.9587 5.61259 23.8022 7.10966 23.5391C8.71585 23.2565 10.0951 23.6746 11.2473 24.7935C12.0242 25.5478 12.4323 26.7043 13.0172 27.6413C17.5826 34.9435 26.8597 36.9543 34.1181 32.3087C34.4803 32.0761 34.7989 31.7761 35.1416 31.513C35.4471 31.2812 35.4864 31.3188 35.2594 31.6261C34.6818 32.4087 34.0424 33.1217 33.3412 33.7652C28.7772 37.9449 23.4254 39.6406 17.2858 38.8522Z"
          fill="#691391"
        />
        <path
          d="M71.1043 34.2695C70.5383 34.2715 69.9945 34.0001 69.5925 33.5152C69.1905 33.0302 68.9634 32.3713 68.961 31.6835C68.9585 30.9957 69.1811 30.3352 69.5797 29.8475C69.9782 29.3597 70.5201 29.0846 71.0862 29.0826C71.6522 29.0807 72.196 29.352 72.598 29.837C73 30.322 73.2271 30.9808 73.2295 31.6687C73.2319 32.3565 73.0094 33.0169 72.6108 33.5047C72.2123 33.9924 71.6704 34.2676 71.1043 34.2695ZM71.0931 33.7391C71.4907 33.7391 71.872 33.5218 72.1532 33.1349C72.4344 32.748 72.5923 32.2232 72.5923 31.6761C72.5923 31.1289 72.4344 30.6042 72.1532 30.2173C71.872 29.8304 71.4907 29.613 71.0931 29.613C70.6954 29.613 70.3141 29.8304 70.0329 30.2173C69.7518 30.6042 69.5938 31.1289 69.5938 31.6761C69.5938 32.2232 69.7518 32.748 70.0329 33.1349C70.3141 33.5218 70.6954 33.7391 71.0931 33.7391Z"
          fill="#FEBD02"
        />
        <path
          d="M77.193 33.1609C77.2803 33.2493 77.3188 33.2312 77.3086 33.1065C77.2141 31.9819 77.1799 30.7659 77.2061 29.4587C77.209 29.2906 77.2948 29.2007 77.4636 29.1891C77.4941 29.1862 77.5254 29.1833 77.5574 29.1804C77.7204 29.1688 77.8019 29.2442 77.8019 29.4065L77.8106 33.9935C77.8106 34.0601 77.7793 34.1058 77.7167 34.1304C77.4309 34.237 77.1799 34.1413 77.0162 33.8761C76.3004 32.708 75.6574 31.558 75.087 30.4261C74.8979 30.0507 74.8135 30.0725 74.8339 30.4913C74.8848 31.5783 74.8943 32.7333 74.8623 33.9565C74.861 34.0149 74.8386 34.0707 74.7992 34.1136C74.7598 34.1564 74.7062 34.1833 74.6484 34.1891C74.628 34.1906 74.6077 34.192 74.5873 34.1935C74.5495 34.1952 74.5118 34.1894 74.4764 34.1762C74.441 34.163 74.4086 34.1428 74.3813 34.1167C74.3539 34.0907 74.3322 34.0594 74.3173 34.0248C74.3025 33.9902 74.2948 33.9529 74.2949 33.9152L74.2861 29.4565C74.2862 29.4105 74.2971 29.3651 74.3179 29.324C74.3387 29.283 74.3689 29.2473 74.4059 29.2198C74.443 29.1924 74.486 29.1739 74.5315 29.166C74.577 29.158 74.6238 29.1607 74.668 29.1739C74.8644 29.2333 75.0187 29.358 75.1307 29.5478C75.8319 30.7319 76.4772 31.8804 77.0664 32.9935C77.0999 33.0558 77.1421 33.1116 77.193 33.1609Z"
          fill="#FEBD02"
        />
        <path
          d="M86.5722 29.7217C85.5116 29.2609 84.5841 30.6109 85.7015 31.0674C86.0972 31.2282 86.4922 31.4072 86.8865 31.6043C88.3334 32.3261 87.8773 34.0109 86.3671 34.2152C85.6658 34.3094 85.0424 34.1109 84.4968 33.6196C84.3921 33.5253 84.3834 33.4225 84.4707 33.3109L84.5274 33.2348C84.6103 33.1275 84.7049 33.1174 84.8111 33.2043C85.3567 33.6522 86.5111 34.0217 86.9934 33.3065C87.3906 32.713 86.9694 32.3065 86.4129 32.0587C85.9175 31.8391 85.3807 31.663 84.9617 31.2848C84.451 30.8282 84.4903 29.9978 84.9835 29.563C85.6266 28.9978 86.4129 28.9739 87.3426 29.4913C87.4924 29.5739 87.5477 29.6985 87.5084 29.8652C87.5041 29.8855 87.4975 29.9051 87.4888 29.9239C87.4291 30.0572 87.3339 30.0906 87.2029 30.0239C86.992 29.9138 86.7817 29.813 86.5722 29.7217Z"
          fill="#FEBD02"
        />
        <path
          d="M79.8926 31.3087L81.6167 31.287C81.6431 31.2863 81.6688 31.296 81.6884 31.3138C81.7079 31.3316 81.7197 31.3563 81.7214 31.3826L81.7367 31.6696C81.7373 31.6832 81.7351 31.6967 81.7304 31.7095C81.7256 31.7223 81.7183 31.7339 81.709 31.7439C81.6996 31.7538 81.6884 31.7617 81.6759 31.7673C81.6634 31.7728 81.65 31.7758 81.6363 31.7761L79.8926 31.7913C79.8658 31.7913 79.84 31.8018 79.8208 31.8205C79.8017 31.8391 79.7906 31.8646 79.79 31.8913L79.7726 33.5522C79.7726 33.5793 79.7834 33.6053 79.8026 33.6244C79.8219 33.6436 79.848 33.6543 79.8752 33.6543L82.0204 33.6304C82.0461 33.6303 82.071 33.6399 82.09 33.6572C82.109 33.6745 82.1208 33.6983 82.123 33.7239L82.1513 34.0196C82.1525 34.0335 82.1508 34.0476 82.1463 34.0608C82.1419 34.0741 82.1347 34.0863 82.1253 34.0967C82.1159 34.1071 82.1044 34.1155 82.0916 34.1213C82.0788 34.1271 82.065 34.1302 82.0509 34.1304L79.3056 34.1478C79.2784 34.1478 79.2523 34.1371 79.233 34.1179C79.2138 34.0987 79.203 34.0727 79.203 34.0456L79.1855 29.3065C79.1855 29.2794 79.1964 29.2534 79.2156 29.2343C79.2348 29.2151 79.2609 29.2043 79.2881 29.2043L81.92 29.2174C81.941 29.2175 81.9615 29.224 81.9787 29.236C81.9958 29.2481 82.0089 29.2651 82.016 29.2848L82.1273 29.6C82.1325 29.6154 82.134 29.6319 82.1315 29.648C82.129 29.6641 82.1227 29.6794 82.1131 29.6926C82.1035 29.7058 82.0909 29.7165 82.0763 29.7239C82.0616 29.7312 82.0455 29.7349 82.0291 29.7348L79.8883 29.7109C79.8746 29.7106 79.861 29.713 79.8483 29.718C79.8356 29.723 79.8241 29.7305 79.8143 29.74C79.8046 29.7495 79.7968 29.7609 79.7915 29.7734C79.7862 29.786 79.7835 29.7994 79.7835 29.813L79.7879 31.2065C79.7879 31.2201 79.7906 31.2336 79.7959 31.2461C79.8012 31.2587 79.8089 31.27 79.8187 31.2795C79.8284 31.2891 79.84 31.2965 79.8527 31.3015C79.8654 31.3066 79.879 31.309 79.8926 31.3087Z"
          fill="#FEBD02"
        />
        <path
          d="M89.5143 29.6913L88.2769 29.7456C88.2484 29.7472 88.2202 29.7383 88.1977 29.7207C88.1753 29.7031 88.16 29.678 88.1547 29.65L88.0958 29.3478C88.0926 29.3309 88.0932 29.3134 88.0975 29.2967C88.1018 29.28 88.1098 29.2645 88.1208 29.2512C88.1319 29.2379 88.1457 29.2272 88.1614 29.2199C88.177 29.2125 88.1941 29.2087 88.2115 29.2087L91.6486 29.213C91.6791 29.213 91.7085 29.2248 91.7304 29.2459C91.7524 29.2669 91.7653 29.2957 91.7665 29.3261L91.7795 29.6217C91.7802 29.6379 91.7774 29.654 91.7715 29.669C91.7656 29.6841 91.7566 29.6977 91.7451 29.7092C91.7336 29.7206 91.7199 29.7295 91.7048 29.7354C91.6897 29.7413 91.6736 29.7441 91.6573 29.7435L90.3654 29.6913C90.3496 29.6907 90.3338 29.6933 90.319 29.6989C90.3042 29.7046 90.2907 29.7131 90.2792 29.7241C90.2678 29.7351 90.2588 29.7482 90.2526 29.7627C90.2464 29.7773 90.2432 29.7929 90.2432 29.8087L90.241 34.0587C90.241 34.0898 90.2286 34.1197 90.2065 34.1417C90.1844 34.1637 90.1544 34.1761 90.1232 34.1761L89.7544 34.1804C89.7231 34.1804 89.6931 34.168 89.671 34.146C89.6489 34.124 89.6365 34.0942 89.6365 34.063V29.8065C89.6362 29.7909 89.6328 29.7755 89.6265 29.7612C89.6202 29.7469 89.6111 29.7341 89.5997 29.7233C89.5883 29.7126 89.5749 29.7042 89.5602 29.6987C89.5456 29.6932 89.53 29.6907 89.5143 29.6913Z"
          fill="#FEBD02"
        />
        <path
          d="M92.7291 32.7717L92.4083 33.863C92.3574 34.037 92.2439 34.1464 92.0679 34.1913C91.9762 34.2145 91.8947 34.1906 91.8234 34.1196C91.7997 34.0954 91.783 34.0651 91.7753 34.0322C91.7676 33.9992 91.7692 33.9648 91.7798 33.9326C92.2424 32.4877 92.7386 31.0159 93.2681 29.5174C93.3729 29.2203 93.5715 29.1087 93.8639 29.1826C93.9309 29.1994 93.9926 29.2329 94.0433 29.28C94.094 29.3271 94.1322 29.3863 94.1542 29.4522L95.6665 33.9348C95.6761 33.9621 95.6791 33.9912 95.6753 34.0199C95.6715 34.0486 95.661 34.0759 95.6447 34.0998C95.6284 34.1237 95.6066 34.1435 95.5812 34.1575C95.5558 34.1715 95.5275 34.1793 95.4985 34.1804L95.4221 34.1826C95.3298 34.1864 95.2389 34.1593 95.1637 34.1058C95.0886 34.0522 95.0336 33.9752 95.0074 33.887L94.7041 32.8674C94.6858 32.8052 94.648 32.7504 94.5961 32.7113C94.5442 32.6722 94.4811 32.6507 94.416 32.65L92.9102 32.637C92.8157 32.637 92.7553 32.6819 92.7291 32.7717ZM92.9932 32.05C92.9897 32.0614 92.9891 32.0735 92.9912 32.0853C92.9933 32.097 92.9982 32.1081 93.0054 32.1176C93.0126 32.1271 93.022 32.1348 93.0328 32.1401C93.0435 32.1453 93.0554 32.148 93.0674 32.1478L94.3702 32.1348C94.382 32.1346 94.3936 32.1317 94.4041 32.1263C94.4146 32.1209 94.4236 32.1132 94.4306 32.1037C94.4376 32.0942 94.4423 32.0833 94.4443 32.0717C94.4463 32.0601 94.4456 32.0482 94.4422 32.037L93.7722 29.9326C93.7675 29.917 93.7578 29.9033 93.7447 29.8935C93.7315 29.8838 93.7155 29.8786 93.6991 29.8786C93.6827 29.8786 93.6668 29.8838 93.6536 29.8935C93.6404 29.9033 93.6308 29.917 93.626 29.9326L92.9932 32.05Z"
          fill="#FEBD02"
        />
        <path
          d="M97.8069 29.8804L97.8003 33.9239C97.8003 33.9579 97.7935 33.9915 97.7803 34.0228C97.767 34.0541 97.7477 34.0824 97.7232 34.1061C97.6988 34.1298 97.6699 34.1484 97.6382 34.1608C97.6064 34.1732 97.5725 34.1791 97.5385 34.1783L97.4228 34.1761C97.3637 34.1744 97.3077 34.1498 97.2665 34.1076C97.2254 34.0654 97.2023 34.0088 97.2024 33.95L97.1958 29.9304C97.1958 29.7565 97.1085 29.6746 96.9339 29.6848L95.9934 29.7391C95.813 29.7493 95.698 29.6674 95.6486 29.4935C95.6296 29.4239 95.6493 29.3522 95.7075 29.2782C95.7252 29.2559 95.7478 29.2379 95.7735 29.2255C95.7991 29.2131 95.8273 29.2066 95.8559 29.2065L99.0901 29.213C99.2472 29.213 99.3309 29.2913 99.341 29.4478C99.344 29.4971 99.3374 29.5456 99.3214 29.5935C99.3077 29.6353 99.2806 29.6714 99.2444 29.6965C99.2082 29.7216 99.1647 29.7343 99.1206 29.7326L97.9967 29.6978C97.8702 29.6935 97.8069 29.7543 97.8069 29.8804Z"
          fill="#FEBD02"
        />
        <path
          d="M102.145 32.7891L101.766 33.9304C101.709 34.1029 101.589 34.1869 101.406 34.1826H101.329C101.3 34.1817 101.272 34.1741 101.247 34.1604C101.221 34.1466 101.199 34.1272 101.183 34.1035C101.166 34.0798 101.155 34.0527 101.151 34.0241C101.147 33.9956 101.15 33.9665 101.159 33.9391C101.66 32.4435 102.163 30.9507 102.669 29.4609C102.726 29.2942 102.879 29.192 103.128 29.1543C103.216 29.1416 103.306 29.1603 103.383 29.2072C103.459 29.2541 103.516 29.3262 103.544 29.4109L105.046 33.913C105.104 34.0855 105.041 34.1717 104.858 34.1717H104.79C104.565 34.1703 104.422 34.0609 104.363 33.8435L104.099 32.8609C104.082 32.7984 104.045 32.7431 103.994 32.7038C103.942 32.6645 103.879 32.6433 103.815 32.6435L102.338 32.65C102.24 32.65 102.176 32.6964 102.145 32.7891ZM102.353 32.0543C102.349 32.0647 102.348 32.0758 102.35 32.0866C102.352 32.0974 102.356 32.1076 102.362 32.1165C102.369 32.1254 102.377 32.1327 102.387 32.1377C102.396 32.1428 102.407 32.1455 102.418 32.1456L103.765 32.1543C103.775 32.1541 103.786 32.1514 103.795 32.1465C103.805 32.1415 103.813 32.1345 103.82 32.1259C103.826 32.1172 103.83 32.1073 103.832 32.0968C103.834 32.0863 103.833 32.0755 103.83 32.0652L103.173 29.9522C103.169 29.9381 103.16 29.9258 103.148 29.9171C103.136 29.9083 103.122 29.9036 103.107 29.9036C103.092 29.9036 103.078 29.9083 103.066 29.9171C103.054 29.9258 103.045 29.9381 103.04 29.9522L102.353 32.0543Z"
          fill="#FEBD02"
        />
        <path
          d="M106.349 29.6891L105.308 29.7413C105.279 29.7427 105.252 29.7367 105.229 29.7239C105.049 29.6196 104.994 29.4891 105.065 29.3326C105.083 29.2944 105.111 29.2621 105.146 29.2393C105.181 29.2166 105.222 29.2044 105.264 29.2043L108.516 29.213C108.644 29.213 108.711 29.2761 108.719 29.4022L108.725 29.5152C108.735 29.6688 108.663 29.742 108.509 29.7348L107.368 29.6891C107.348 29.6882 107.328 29.6915 107.309 29.6986C107.29 29.7058 107.273 29.7167 107.259 29.7307C107.244 29.7447 107.233 29.7615 107.225 29.7802C107.217 29.7988 107.213 29.8189 107.213 29.8391L107.208 33.8761C107.208 34.0457 107.126 34.1507 106.962 34.1913C106.895 34.2087 106.824 34.2036 106.75 34.1761C106.706 34.1608 106.668 34.1324 106.641 34.095C106.614 34.0576 106.6 34.013 106.6 33.9674L106.58 29.9065C106.58 29.8768 106.574 29.8475 106.562 29.8202C106.55 29.7929 106.533 29.7684 106.511 29.748C106.49 29.7276 106.464 29.7119 106.436 29.7018C106.408 29.6917 106.378 29.6873 106.349 29.6891Z"
          fill="#FEBD02"
        />
        <path
          d="M111.577 32.7196L111.152 33.9631C111.13 34.0283 111.087 34.0849 111.032 34.1251C110.976 34.1652 110.909 34.1869 110.84 34.187L110.752 34.1848C110.568 34.1848 110.505 34.0978 110.565 33.9239C111.058 32.4689 111.542 31.0167 112.018 29.5674C112.095 29.3326 112.246 29.2015 112.47 29.1739C112.576 29.1609 112.684 29.185 112.775 29.242C112.866 29.2991 112.934 29.3856 112.968 29.487L114.456 33.9239C114.51 34.0862 114.452 34.1717 114.281 34.1804L114.192 34.1848C113.994 34.1949 113.865 34.1058 113.806 33.9174L113.448 32.7913C113.431 32.7388 113.397 32.6936 113.351 32.6634C113.305 32.6333 113.249 32.6201 113.194 32.6261C112.726 32.6797 112.25 32.6725 111.765 32.6044C111.671 32.5913 111.608 32.6297 111.577 32.7196ZM111.774 32.0044C111.769 32.0207 111.767 32.038 111.77 32.0549C111.773 32.0718 111.78 32.0878 111.79 32.1015C111.8 32.1153 111.814 32.1265 111.829 32.1342C111.844 32.1418 111.861 32.1458 111.879 32.1457H113.107C113.124 32.1453 113.14 32.1412 113.155 32.1335C113.17 32.1258 113.183 32.1148 113.193 32.1013C113.203 32.0879 113.21 32.0723 113.212 32.0559C113.215 32.0394 113.214 32.0226 113.21 32.0065L112.601 29.9804C112.594 29.9579 112.581 29.938 112.562 29.9239C112.543 29.9098 112.52 29.9022 112.496 29.9022C112.473 29.9022 112.45 29.9098 112.431 29.9239C112.412 29.938 112.398 29.9579 112.391 29.9804L111.774 32.0044Z"
          fill="#FEBD02"
        />
        <path
          d="M117.678 29.6913L116.525 29.7391C116.474 29.7409 116.423 29.7225 116.385 29.6879C116.347 29.6533 116.325 29.6053 116.322 29.5543L116.316 29.4456C116.309 29.2862 116.386 29.2065 116.547 29.2065L119.795 29.2109C119.927 29.2109 120.003 29.2761 120.021 29.4065C120.026 29.434 120.024 29.4609 120.015 29.4869C119.957 29.6551 119.839 29.7362 119.661 29.7304L118.575 29.6935C118.554 29.6929 118.534 29.6964 118.515 29.7038C118.496 29.7112 118.478 29.7223 118.464 29.7365C118.449 29.7507 118.437 29.7676 118.43 29.7864C118.422 29.8051 118.417 29.8253 118.417 29.8456L118.402 33.9674C118.401 34.1022 118.333 34.1659 118.199 34.1587L118.053 34.1522C117.892 34.1449 117.812 34.0616 117.813 33.9022L117.826 29.8348C117.826 29.8155 117.823 29.7963 117.815 29.7785C117.808 29.7606 117.797 29.7445 117.783 29.7311C117.769 29.7176 117.753 29.7071 117.734 29.7003C117.716 29.6935 117.697 29.6904 117.678 29.6913Z"
          fill="#FEBD02"
        />
        <path
          d="M121.263 29.1891H121.027C120.925 29.1891 120.842 29.2719 120.842 29.3739V33.9783C120.842 34.0803 120.925 34.1631 121.027 34.1631H121.263C121.365 34.1631 121.448 34.0803 121.448 33.9783V29.3739C121.448 29.2719 121.365 29.1891 121.263 29.1891Z"
          fill="#FEBD02"
        />
        <path
          d="M126.19 30.5913L125.23 33.213C125.004 33.8261 124.773 33.829 124.536 33.2217C124.214 32.4 123.867 31.4159 123.495 30.2696C123.482 30.2275 123.455 30.1978 123.414 30.1804C123.402 30.1755 123.389 30.1738 123.376 30.1755C123.363 30.1771 123.35 30.1822 123.34 30.19C123.329 30.1979 123.321 30.2084 123.315 30.2204C123.31 30.2324 123.308 30.2456 123.309 30.2587C123.41 31.437 123.432 32.6732 123.375 33.9674C123.374 33.9978 123.366 34.0277 123.353 34.0551C123.339 34.0824 123.32 34.1067 123.297 34.1263C123.273 34.1459 123.246 34.1605 123.217 34.1691C123.187 34.1776 123.156 34.18 123.126 34.1761L123.017 34.163C122.967 34.1572 122.921 34.1329 122.888 34.0949C122.854 34.0568 122.836 34.0076 122.836 33.9565L122.827 29.3739C122.827 29.3395 122.838 29.3059 122.858 29.2781C122.879 29.2503 122.908 29.2298 122.941 29.2196C123.31 29.1094 123.552 29.2065 123.667 29.5109C124.037 30.4978 124.386 31.4754 124.715 32.4435C124.852 32.842 124.978 32.8391 125.095 32.4348C125.387 31.429 125.744 30.4232 126.166 29.4174C126.198 29.3406 126.253 29.2841 126.33 29.2478C126.487 29.1739 126.646 29.1594 126.808 29.2043C126.907 29.2333 126.956 29.2985 126.956 29.4L126.96 33.9761C126.961 34.0019 126.955 34.0274 126.945 34.0511C126.934 34.0747 126.919 34.0959 126.9 34.1133C126.88 34.1307 126.857 34.1439 126.833 34.1521C126.808 34.1603 126.781 34.1633 126.755 34.1609L126.565 34.1435C126.427 34.1304 126.358 34.0543 126.358 33.9152C126.352 32.8326 126.379 31.7413 126.439 30.6413C126.469 30.0703 126.387 30.0536 126.19 30.5913Z"
          fill="#FEBD02"
        />
        <path
          d="M128.925 29.7891L128.931 31.25C128.931 31.2692 128.939 31.2877 128.952 31.3015C128.966 31.3153 128.984 31.3234 129.003 31.3239L130.854 31.3913C130.864 31.3915 130.875 31.3939 130.884 31.3983C130.893 31.4027 130.902 31.409 130.908 31.4167C130.915 31.4245 130.92 31.4336 130.923 31.4435C130.926 31.4533 130.927 31.4637 130.926 31.4739L130.898 31.6978C130.896 31.7152 130.887 31.7312 130.875 31.7432C130.862 31.7551 130.845 31.7621 130.828 31.7631L128.999 31.8435C128.98 31.8446 128.963 31.8525 128.95 31.8658C128.937 31.8791 128.93 31.8967 128.929 31.9152L128.918 33.5652C128.918 33.5848 128.926 33.6036 128.94 33.6175C128.954 33.6313 128.973 33.6391 128.992 33.6391L131.129 33.6457C131.146 33.6459 131.163 33.6521 131.176 33.6632C131.189 33.6743 131.198 33.6896 131.201 33.7065L131.249 33.95C131.251 33.9606 131.25 33.9714 131.248 33.9818C131.245 33.9922 131.24 34.0018 131.233 34.0101C131.226 34.0184 131.218 34.0251 131.208 34.0297C131.198 34.0343 131.188 34.0368 131.177 34.037L128.397 34.1283C128.387 34.1286 128.377 34.1269 128.368 34.1233C128.358 34.1197 128.35 34.1143 128.343 34.1074C128.336 34.1005 128.33 34.0923 128.326 34.0832C128.322 34.074 128.32 34.0642 128.32 34.0544L128.322 29.2565C128.322 29.2466 128.324 29.2368 128.328 29.2277C128.332 29.2186 128.338 29.2104 128.345 29.2035C128.352 29.1966 128.361 29.1912 128.37 29.1876C128.379 29.184 128.389 29.1823 128.399 29.1826L131.059 29.2348C131.077 29.2353 131.095 29.2424 131.108 29.2548C131.121 29.2672 131.13 29.2841 131.131 29.3022L131.175 29.6804C131.176 29.691 131.175 29.7018 131.172 29.7119C131.168 29.7221 131.163 29.7314 131.156 29.7393C131.148 29.7471 131.14 29.7533 131.13 29.7574C131.12 29.7615 131.109 29.7634 131.098 29.7631L129.001 29.7152C128.991 29.7149 128.981 29.7166 128.972 29.7202C128.963 29.7238 128.954 29.7292 128.947 29.7361C128.94 29.743 128.935 29.7512 128.931 29.7603C128.927 29.7695 128.925 29.7792 128.925 29.7891Z"
          fill="#FEBD02"
        />
        <path
          d="M66.9054 31.4152H46.4877C46.378 31.4152 46.2891 31.5038 46.2891 31.613V31.7478C46.2891 31.8571 46.378 31.9457 46.4877 31.9457H66.9054C67.0151 31.9457 67.104 31.8571 67.104 31.7478V31.613C67.104 31.5038 67.0151 31.4152 66.9054 31.4152Z"
          fill="#FEBD02"
        />
        <path
          d="M153.693 31.4351L133.301 31.3997C133.156 31.3994 133.039 31.516 133.039 31.6601L133.039 31.6644C133.038 31.8085 133.155 31.9255 133.3 31.9257L153.692 31.9612C153.836 31.9614 153.954 31.8449 153.954 31.7008L153.954 31.6964C153.954 31.5524 153.837 31.4354 153.693 31.4351Z"
          fill="#FEBD02"
        />
      </g>
      <defs>
        <clipPath id="clip0_919_23972">
          <rect width="158" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoSm;
