import * as React from "react";

function UploadIcon(props) {
  return (
    <svg
      width="50"
      height="53"
      viewBox="0 0 50 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <rect x="2.5" y="5.5" width="47" height="47" rx="23.5" fill="#424652" />
      <rect x="2.5" y="5.5" width="47" height="47" rx="23.5" stroke="#616877" />
      <path
        d="M30 29L26 25M26 25L22 29M26 25V34.2C26 35.5907 26 36.2861 26.5505 37.0646C26.9163 37.5819 27.9694 38.2203 28.5972 38.3054C29.5421 38.4334 29.9009 38.2462 30.6186 37.8719C33.8167 36.2036 36 32.8568 36 29C36 23.4772 31.5228 19 26 19C20.4772 19 16 23.4772 16 29C16 32.7014 18.011 35.9331 21 37.6622"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect width="18" height="18" rx="9" fill="#F7AD0B" />
      <path
        d="M6.82889 13.5L6.43289 12.516L9.00089 9.624C9.13689 9.48 9.27689 9.316 9.42089 9.132C9.57289 8.94 9.71689 8.744 9.85289 8.544C9.98889 8.344 10.0969 8.148 10.1769 7.956C10.2649 7.756 10.3089 7.568 10.3089 7.392C10.3089 7.136 10.2569 6.92 10.1529 6.744C10.0569 6.56 9.91289 6.42 9.72089 6.324C9.53689 6.228 9.31289 6.18 9.04889 6.18C8.81689 6.18 8.58889 6.248 8.36489 6.384C8.14089 6.52 7.93689 6.712 7.75289 6.96C7.56889 7.208 7.42489 7.496 7.32089 7.824L6.32489 7.2C6.46089 6.736 6.66489 6.34 6.93689 6.012C7.21689 5.676 7.54889 5.42 7.93289 5.244C8.32489 5.068 8.74889 4.98 9.20489 4.98C9.67689 4.98 10.0969 5.08 10.4649 5.28C10.8329 5.472 11.1209 5.744 11.3289 6.096C11.5369 6.44 11.6409 6.84 11.6409 7.296C11.6409 7.48 11.6209 7.668 11.5809 7.86C11.5409 8.052 11.4769 8.252 11.3889 8.46C11.3009 8.66 11.1889 8.872 11.0529 9.096C10.9169 9.312 10.7529 9.54 10.5609 9.78C10.3769 10.02 10.1649 10.272 9.92489 10.536L8.01689 12.612L7.77689 12.252H11.9049V13.5H6.82889Z"
        fill="white"
      />
    </svg>
  );
}

export default UploadIcon;
