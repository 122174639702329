import * as React from "react";

function TwitterIcon(props) {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        id="Symbol"
        d="M19.7477 4.48105C19.7611 4.67634 19.7611 4.87163 19.7611 5.06872C19.7611 11.074 15.2208 18 6.91863 18V17.9964C4.46614 18 2.06459 17.2926 0 15.9589C0.356612 16.0021 0.715011 16.0237 1.0743 16.0246C3.10672 16.0264 5.08105 15.3398 6.67999 14.0753C4.74857 14.0384 3.05489 12.7704 2.46321 10.9192C3.13979 11.0506 3.83693 11.0236 4.501 10.841C2.39529 10.4126 0.880358 8.5497 0.880358 6.38623C0.880358 6.36643 0.880358 6.34754 0.880358 6.32864C1.50778 6.68051 2.21028 6.8758 2.92886 6.8974C0.945602 5.56279 0.334268 2.90615 1.53191 0.829084C3.82352 3.6684 7.20463 5.3945 10.8342 5.57718C10.4704 3.99868 10.9674 2.34459 12.14 1.23496C13.9579 -0.485733 16.8171 -0.397539 18.5259 1.43205C19.5368 1.23136 20.5056 0.857882 21.3922 0.328715C21.0553 1.38075 20.3501 2.27439 19.4081 2.84226C20.3027 2.73606 21.1768 2.49488 22 2.1268C21.394 3.04114 20.6308 3.83759 19.7477 4.48105Z"
        fill="#1D9BF0"
      />
    </svg>
  );
}

export default TwitterIcon;
