import * as React from "react";

function ReportCard(props) {
  return (
    <svg
      width="50"
      height="53"
      viewBox="0 0 50 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <rect x="2.5" y="5.5" width="47" height="47" rx="23.5" fill="#424652" />
      <rect x="2.5" y="5.5" width="47" height="47" rx="23.5" stroke="#616877" />
      <path
        d="M24 23H18C17.7348 23 17.4804 23.1054 17.2929 23.2929C17.1054 23.4804 17 23.7348 17 24V36C17 36.2652 17.1054 36.5196 17.2929 36.7071C17.4804 36.8946 17.7348 37 18 37H34C34.2652 37 34.5196 36.8946 34.7071 36.7071C34.8946 36.5196 35 36.2652 35 36V24C35 23.7348 34.8946 23.4804 34.7071 23.2929C34.5196 23.1054 34.2652 23 34 23H28M28 33H30M28 29H32M21 29H24V33H21V29ZM24 21C24 20.7348 24.1054 20.4804 24.2929 20.2929C24.4804 20.1054 24.7348 20 25 20H27C27.2652 20 27.5196 20.1054 27.7071 20.2929C27.8946 20.4804 28 20.7348 28 21V24C28 24.2652 27.8946 24.5196 27.7071 24.7071C27.5196 24.8946 27.2652 25 27 25H25C24.7348 25 24.4804 24.8946 24.2929 24.7071C24.1054 24.5196 24 24.2652 24 24V21Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect width="18" height="18" rx="9" fill="#F7AD0B" />
      <path
        d="M9.5033 13.5V6.732L9.8033 6.804L7.0433 10.176L6.9953 10.032H12.2153V11.22H6.1913L5.6393 10.14L9.7553 5.1H10.7513V13.5H9.5033Z"
        fill="white"
      />
    </svg>
  );
}

export default ReportCard;
