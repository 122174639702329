import * as React from "react";

function AiIcon(props) {
  return (
    <svg
      width="50"
      height="53"
      viewBox="0 0 50 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <rect x="2.5" y="5.5" width="47" height="47" rx="23.5" fill="#424652" />
      <rect x="2.5" y="5.5" width="47" height="47" rx="23.5" stroke="#616877" />
      <path
        d="M18 35V29.5C18 28.833 18.167 28.167 18.5 27.5M26 24.4999C26 23.4999 25.99 20.4299 22 20.9999C18.5 21.4999 18 23.4999 18 24.4999C18 25.9999 18 28.4999 21 28.4999C25 28.4999 26 25.9999 26 24.4999ZM26 24.4999L26.0001 24.203L26.0101 23.934L26.0371 23.636L26.0501 23.531L26.0831 23.316C26.0971 23.243 26.1121 23.17 26.1291 23.096L26.1891 22.873C26.5251 21.755 27.4511 20.636 29.9971 21C32.8351 21.405 33.7001 22.797 33.9271 23.842L33.9631 24.046C33.9631 24.079 33.9731 24.112 33.9761 24.144L33.9921 24.329V24.99L33.9771 25.384L33.9571 25.655C33.8351 27.021 33.3021 28.5 30.9951 28.5C27.7391 28.5 26.4711 26.844 26.1121 25.419L26.0591 25.177C26.0447 25.099 26.0327 25.0207 26.0231 24.942L26.0021 24.715C25.9976 24.6434 25.9953 24.5717 25.9951 24.5L26 24.4999ZM18 29C16.667 29.667 16 30.333 16 31C16 32 16 34 17.5 35C20.5 37 24 38 26 38C28 38 31.499 37 34.5 35C36 34 36 32 36 31C36 30.333 35.333 29.667 34 29M34 35V29.5C34 28.833 33.833 28.167 33.5 27.5M24 32V34M28 32V34"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect width="18" height="18" rx="9" fill="#F7AD0B" />
      <path
        d="M8.83622 13.62C8.24422 13.62 7.72822 13.484 7.28822 13.212C6.85622 12.94 6.44822 12.484 6.06422 11.844L7.07222 11.208C7.27222 11.504 7.46822 11.748 7.66022 11.94C7.86022 12.124 8.06422 12.26 8.27222 12.348C8.48022 12.436 8.70022 12.48 8.93222 12.48C9.21222 12.48 9.47222 12.412 9.71222 12.276C9.95222 12.132 10.1442 11.936 10.2882 11.688C10.4322 11.44 10.5042 11.16 10.5042 10.848C10.5042 10.536 10.4482 10.272 10.3362 10.056C10.2242 9.832 10.0602 9.664 9.84422 9.552C9.62822 9.432 9.37222 9.372 9.07622 9.372C8.94022 9.372 8.79622 9.388 8.64422 9.42C8.50022 9.444 8.34422 9.484 8.17622 9.54C8.00822 9.588 7.82422 9.652 7.62422 9.732L7.26422 8.784L9.85622 5.928L10.1082 6.3H6.53222V5.1H11.3442L11.5842 5.58L8.83622 8.628L8.53622 8.316C8.60022 8.284 8.70422 8.256 8.84822 8.232C9.00022 8.2 9.12422 8.184 9.22022 8.184C9.57222 8.184 9.90022 8.252 10.2042 8.388C10.5162 8.516 10.7842 8.7 11.0082 8.94C11.2402 9.172 11.4202 9.448 11.5482 9.768C11.6842 10.08 11.7522 10.416 11.7522 10.776C11.7522 11.32 11.6202 11.808 11.3562 12.24C11.1002 12.664 10.7522 13 10.3122 13.248C9.87222 13.496 9.38022 13.62 8.83622 13.62Z"
        fill="white"
      />
    </svg>
  );
}

export default AiIcon;
